import http from '~/utils/http'

export class FreeVipApi {
  // @POST('/jav/zone/query')
  // Future<BannerFilmPageResponse> query(@Body() VideoFilterParam param);
  static query(params: VideoFilterParam, opt?: HttpOptions) {
    return http.post<BannerFilmPageResponse>({
      ...opt,
      url: '/jav/zone/query',
      body: params
    })
  }
}
